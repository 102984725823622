import React from "react";
import { Container, Grid, Typography, Box, useMediaQuery } from "@mui/material";
import Images from "../../../components/theme-constants/image";
import CardMedia from "@mui/material/CardMedia";


function AboutTop() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
   
    return (
      <Box className="about-top-section" sx={{ pt:13 }}>
        <Container maxWidth="xl" className="container">
        <Box className="item">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid className="content order2" item xs={isSmallScreen ? 12 : 7} >
                  <Typography
                    variant="h3"
                    component="h1"
                    gutterBottom
                    alignItems="flex-end"
                  >
                Building bridges between
                <Typography variant="span">companies and customers over 16 Years</Typography>

                  </Typography>
                  
                  <Typography variant="body1" gutterBottom>
                  We at F9 make sure our clients and employees grow along with the growth of the company by providing a great and unique work-life culture in the industry. Our team works closely with our clients and always give importance to their preferences as not just delivering the product on time but to leave an impression on the user being easy to use and keeping in mind the interest of its users.
                  </Typography>
                </Grid>
                <Grid className="pic order1" item xs={isSmallScreen ? 12 : 5} >
                 
                  <figure><CardMedia
                  component="img"
                 
                  image={Images.AboutTopImage.default}
                  alt="F9tech"
                /></figure>
                 
                </Grid>
              </Grid>
            </Box>
        </Container>
      </Box>
    );
  }
  
  export default AboutTop;