import React from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Container,
  Box,
  Grid,
  Button,
  CardActionArea,
  CardActions,
  useMediaQuery
} from "@mui/material";
import Sectiontitle from "../../components/section-title";
import Images from "../../components/theme-constants/image";
function Blog() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  return (
    <Box sx={{ py: 13 }} className="blog">
      <Container maxWidth="xl" className="container">
        <Sectiontitle
          data={{
            mainTitle: "What’s new",
            subTitle:
              "Web and Mobile discloseered lorem ipsum App Development Company",
          }}
        />
        <Grid
          container
          spacing={isSmallScreen ? 0 : 5}
          justifyContent="center"
          alignItems="left"
          sx={{ pt: isSmallScreen ? 0 : 13 }}
        >
          <Grid item xs={isSmallScreen ? 12 : 4}  className="block">
          <Box className="card">
              <CardActionArea>
            <figure><CardMedia
                  component="img"
                  height="140"
                  image={Images.Blog1}
                  alt="F9tech"
                /></figure>
                <CardContent className="card-content">
                  <Typography gutterBottom variant="h5" component="div" className="title">
                    Why flutter is the best choice for your app?
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    One of the most important things to consider when building a
                    Flutter app is that you need to use only one codebase...
                  </Typography>

                  <CardActions className="card-actions">
                <Button size="small" variant="outlined" color="primary">
                  Read More
                </Button>
              </CardActions>

                </CardContent>
            
            
              </CardActionArea>
            </Box>
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 4}className="block">
          <Box className="card">
              <CardActionArea>
              <figure><CardMedia
                  component="img"
                  height="140"
                  image={Images.Blog2}
                  alt="F9tech"
                /></figure>
                <CardContent className="card-content">
                  <Typography gutterBottom variant="h5" component="div" className="title">
                  Advantages by single codebase in Flutter
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  Programming languages are an important part of app development. In this post, we will discuss the best programming languages and what they can do for you...
                  </Typography>
                  <CardActions className="card-actions">
                <Button size="small" variant="outlined" color="primary">
                  Read More
                </Button>
              </CardActions>
                </CardContent>
           
              
              </CardActionArea>
            </Box>
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 4} className="block">
            <Box className="card">
              <CardActionArea>
              <figure><CardMedia
                  component="img"
                  height="140"
                  image={Images.Blog3}
                  alt="F9tech"
                /></figure>
               <CardContent className="card-content">
                  <Typography gutterBottom variant="h5" component="div" className="title">
                  5 Reasons why Apple devices are the ideal choice for you
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  Apple devices are the most popular in the world. Apple has a dedicated ecosystem of hardware and software that is built for all. Their ecosystem is built for all, with many different devices to choose from...
                  </Typography>
                  <CardActions className="card-actions">
                <Button size="small" variant="outlined" color="primary">
                  Read More
                </Button>
              </CardActions>
                </CardContent>
         
            
              </CardActionArea>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default Blog;
