import React, { Component } from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import Images from "../../components/theme-constants/image";
export default class Welcome extends Component {
  render() {
    return (
      <Box className="welcome-section pt-5">
        <Container maxWidth="xl" className="container">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="start"
          >
            <Grid className="content" item xs={4}>
<figure>
<CardMedia
          component="img"
  
          image={Images.Logo14.default} 
          alt="F9tech"
        />
</figure>
            </Grid>
            <Grid className="pic" item xs={8}>
              <Typography
                variant="h3"
                component="div"
                gutterBottom
                alignItems="flex-end"
                className="welcome-title"
              >
                Delivering digital solutions to make businesses Agile, Scalable
                and Efficient
              </Typography>
              <Typography variant="body1" gutterBottom>
              F9 Info Technologies is a Global Digital Solutions Company that helps businesses ensure their success by harnessing the power of technology. We offer customised mobile app and web development services, we have strong expertise in design, digital marketing and web services. Our end-to-end services, from creating a design concept and planning the product development right through to launching the software, we ensure that our clients are always successful. We are proud to have delivered experience along with technology to over 1000 clients across the globe.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
}
