const DevelopmentProcessList = [
    {
        title: "Backlog",
        body: "All requirements will be finalised and kept in backlog. Ready to be moved into sprints.",
        imageUrl: require("../images/development/1.svg").default,
      },
      {
        title: "Plan",
        body: "It’s time to break down  project requirements into theme, epics and user stories.⁣",
        imageUrl: require("../images/development/2.svg").default,
      },
      {
        title: "Design",
        body: "We finalise the prototype as per our design requirement and move to next phase.",
        imageUrl: require("../images/development/3.svg").default,
      },
      {
        title: "Develop",
        body: "We convert the designs into functional features with our expertise.",
        imageUrl: require("../images/development/4.svg").default,
      },
      {
        title: "Testing",
        body: "We lookout for bugs, or any technical glitches that may arise in the code.",
        imageUrl: require("../images/development/5.svg").default,
      },
      {
        title: "Deploy",
        body: "We deploy the bug free code on secure servers via pipelines, ensuring its quality and stability.",
        imageUrl: require("../images/development/6.svg").default,
      },
      {
        title: "Review",
        body: "The deployed application is tested with multiple test cases and documented.",
        imageUrl: require("../images/development/7.svg").default,
      },
      {
        title: "Release",
        body: "We present the working product or software to stakeholders and customers for use..",
        imageUrl: require("../images/development/8.svg").default,
      },
  
  // Add more items as needed
];
export default DevelopmentProcessList;
