import React from "react";
import { Grid, Typography, Box, useMediaQuery } from "@mui/material";
import Images from "../../../components/theme-constants/image";
import CardMedia from "@mui/material/CardMedia";

function WeOffer() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box className="weoffer-section" sx={{ py: 13 }}>
      <Typography
        variant="h1"
        component={"h1"}
        className="page-title"
        align="center"
      >
        Solutions
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="block"
      >
        <Grid className="pic order1" item xs={isSmallScreen ? 12 : 6} sx={{ p: 0 }}>
          <figure>
            <CardMedia component="img" image={Images.S1} alt="F9tech" />
          </figure>
        </Grid>
        <Grid className="content order2" item xs={isSmallScreen ? 12 : 6} sx={{ p: 0 }}>
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            alignItems="flex-end"
          >
            Association Management maintenance and support services count.
          </Typography>
          <Typography variant="body1" gutterBottom>
            As a company, we believe that transparency is paramount and that
            consumers deserve to have the ability to make their own choices.
            That's why we're committed to contributing to the ONDC, a national
            initiative that aims at promoting open networks for all aspects of
            exchange of goods and services over digital networks. As a company,
            we believe that transparency is paramount and that consumers deserve
            to have the ability to make their own choices. That's why we're
            committed to contributing to the ONDC,"
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="block"
      >
        <Grid className="pic order1" item xs={isSmallScreen ? 12 : 6} sx={{ p: 0 }}>
          <figure>
            <CardMedia component="img" image={Images.S2} alt="F9tech" />
          </figure>
        </Grid>
        <Grid className="content order2" item xs={isSmallScreen ? 12 : 6} sx={{ p: 0 }}>
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            alignItems="flex-end"
          >
            Flutter CMS the best Application Support
          </Typography>
          <Typography variant="body1" gutterBottom>
            As a company, we believe that transparency is paramount and that
            consumers deserve to have the ability to make their own choices.
            That's why we're committed to contributing to the ONDC, a national
            initiative that aims at promoting open networks for all aspects of
            exchange of goods and services over digital networks. As a company,
            we believe that transparency is paramount and that consumers deserve
            to have the ability to make their own choices. That's why we're
            committed to contributing to the ONDC,
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="block"
      >
        <Grid className="pic order1" item xs={isSmallScreen ? 12 : 6} sx={{ p: 0 }}>
          <figure>
            <CardMedia component="img" image={Images.S3} alt="F9tech" />
          </figure>
        </Grid>
        <Grid className="content order2" item xs={isSmallScreen ? 12 : 6} sx={{ p: 0 }}>
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            alignItems="flex-end"
          >
            PR CMS maintenance and support services count.
          </Typography>
          <Typography variant="body1" gutterBottom>
            As a company, we believe that transparency is paramount and that
            consumers deserve to have the ability to make their own choices.
            That's why we're committed to contributing to the ONDC, a national
            initiative that aims at promoting open networks for all aspects of
            exchange of goods and services over digital networks. As a company,
            we believe that transparency is paramount and that consumers deserve
            to have the ability to make their own choices. That's why we're
            committed to contributing to the ONDC,"
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="block"
      >
        <Grid className="pic order1" item xs={isSmallScreen ? 12 : 6} sx={{ p: 0 }}>
          <figure>
            <CardMedia component="img" image={Images.S4} alt="F9tech" />
          </figure>
        </Grid>
        <Grid className="content order2" item xs={isSmallScreen ? 12 : 6} sx={{ p: 0 }}>
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            alignItems="flex-end"
          >
            F9 CRM the best Application Support
          </Typography>
          <Typography variant="body1" gutterBottom>
            As a company, we believe that transparency is paramount and that
            consumers deserve to have the ability to make their own choices.
            That's why we're committed to contributing to the ONDC, a national
            initiative that aims at promoting open networks for all aspects of
            exchange of goods and services over digital networks. As a company,
            we believe that transparency is paramount and that consumers deserve
            to have the ability to make their own choices. That's why we're
            committed to contributing to the ONDC,"
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WeOffer;
