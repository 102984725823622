import Sectiontitle from "../../components/section-title";
import { Grid, Container, Box, useMediaQuery } from "@mui/material";
import ServiceCard from "../custom-cards/service-card";
import Servicecardlist from "../../lists/list-service";


function Service() {
  // Slice the service card list to display only the first 8 items
  const limitedServiceCardList = Servicecardlist.slice(0, 8);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <Box className="bg-grey home-services" sx={{ py: 13 }}>
      <Container maxWidth="xl" className="container">
        <Sectiontitle
          data={{
            mainTitle: "Services",
            subTitle:
              "From Strategy to Results, We’ll be there from Start to End",
          }}
        />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {limitedServiceCardList.map((item, index) => (
            <Grid item xs={isSmallScreen ? 12 : 3}  key={index}>
              <ServiceCard
                title={item.title}
                body={item.body}
                imgUrl={item.imageUrl}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Service;