import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, Typography, useMediaQuery } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../components/theme-constants/color/color.css";

import PortfoliolList from "../../lists/list-portfolio"

function Portfolio() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
  return (
    <Box sx={{ py: 13 }} className="portfolio">
      <Typography variant="h2">Portfolio</Typography>
      <Grid container spacing={5} justifyContent="center" alignItems="left">
        <Grid item xs={isSmallScreen ? 12 : 5} className="left-slider">
          <Box className="content">
            <Slider
              asNavFor={nav2}
              ref={(slider) => (sliderRef1 = slider)}
              autoplay={true} // Auto slide
              autoplaySpeed={10000} // Slide interval in milliseconds
            >
              {PortfoliolList.map((item, index) => (
               
                  <Box className="text">
                    <h3>{item.title}</h3>
                    <p>{item.body}</p>
                  </Box>
                 
               
              ))}
            </Slider>
          </Box>
        </Grid>
        <Grid item xs={isSmallScreen ? 12 : 7} className="right-slider">
          <Box className="webimage">
            <Slider
              asNavFor={nav1}
              ref={(slider) => (sliderRef2 = slider)}
              slidesToShow={1}
              swipeToSlide={true}
              focusOnSelect={true}
              arrows={false}
              autoplay={true} // Auto slide
              autoplaySpeed={10000} // Slide interval in milliseconds
            >
              {PortfoliolList.map((item, index) => (
  <Box key={index}>
    
    <figure className="img1">
     
      <CardMedia
        component="img"
        image={item.mobimageUrl}
        alt={item.title}
      />
    </figure>
    <figure className="img2">
      <CardMedia
        component="img"
        image={item.webimageUrl}
        alt={item.title}
      />
    </figure>
  </Box>
))}
            </Slider>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
export default Portfolio;
