import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import AboutTop from "../../components/about-blocks/about-top";
import Vision from "../../components/about-blocks/vision";
import Experience from "../../components/about-blocks/experience";

class About extends Component {
    render() {
        return (
            <Box>
               
                <Helmet>
                    <title>About Us | F9 Technologies</title>
                    <meta name="description" content="Reach out to Vizag's highly skilled and knowledgeable software expertise." />
                </Helmet>
                <AboutTop/>
                <Vision/>
                <Experience/>
            </Box>
        );
    }
}

export default About;
