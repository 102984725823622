import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import './index.css'


function processcate(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.slice(0, maxLength)}...`;
}

function ProcessCard({ title = "Title", body = "Body", imageUrl="https://www.chicagoandhra.org/images/events/mdf8wljqn.jpg", }) {
  const processcatedTitle = processcate(title, 24);
  const processcatedBody = processcate(body, 62); // Adjust the maximum length as needed

  return (
    <Card className="process-card">
   
        <figure>
          <CardMedia
            component="img"
            image={imageUrl}
            alt="F9tech"
          />
        </figure>
        <CardContent>
          <Typography className="title" gutterBottom variant="h5" component="div">
            {processcatedTitle}
          </Typography>
          <Typography component="p" color="text.secondary">
            {processcatedBody}
          </Typography>
        </CardContent>
     
    </Card>
  );
}

export default ProcessCard;
