import * as React from "react";
import "../header/header.css";
import Nav from "../navigation/nav";
import MobileMenu from "../navigation/mobile-menu";
import logo from "../../logo.svg";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import {
  AppBar,
  Container,
  Grid,
  Link,
  Box,
  Card,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
export default function Header(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          position="static"
          style={{ background: "transparent", boxShadow: "none" }}
        >
          <Container maxWidth="xl" className="container">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid className="logo" item md={3} sm="9">
                <Link href="/">
                  <img
                    src={logo}
                    className="App-logo"
                    alt="F9 Info Technologies"
                  />
                </Link>
              </Grid>
              <Grid className="right-nav" item md={9} sm="3">
                <Box
                  sx={{
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  <Nav />
                </Box>
                <MobileMenu />
              </Grid>
            </Grid>
          </Container>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}
