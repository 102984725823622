import React from 'react';
import { Box, Container, Grid, Typography } from "@mui/material";


    function Experience() {
  
        return (
            <Box className="about-experience-section" sx={{ py:13 }}>
                 <Container maxWidth="xl" className="container">
                 <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
               
              >
                <Grid item className='block'  >
                    <Typography variant='p' component={'p'} >
                    Industry Experience
                    </Typography>
                    <Typography variant='h6' component={'h6'} >
                    16 Years
                    </Typography>
                    </Grid>

                    <Grid item className='block'  >
                    <Typography variant='p' component={'p'} >
                    Team Strength
                    </Typography>
                    <Typography variant='h6' component={'h6'} >
                    20+
                    </Typography>
                    </Grid>

                    <Grid item className='block'  >
                    <Typography variant='p' component={'p'} >
                    Avg. years of Experience 
                    </Typography>
                    <Typography variant='h6' component={'h6'} >
                    5+ Years
                    </Typography>
                    </Grid>

                    <Grid item className='block'  >
                    <Typography variant='p' component={'p'} >
                    Clients
                    </Typography>
                    <Typography variant='h6' component={'h6'} >
                    500+
                    </Typography>
                    </Grid>

                    <Grid item className='block'  >
                    <Typography variant='p' component={'p'} >
                    Websites Developed
                    </Typography>
                    <Typography variant='h6' component={'h6'} >
                    1000+
                    </Typography>
                    </Grid>

                   




                    </Grid>
                 </Container>
            </Box>
        );
    }

export default Experience;