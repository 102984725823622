import React from "react";
// import Header from "../../components/header";
import Hero from "../../components/hero/hero";
import Welcome from "../../components/home-blocks/welcome";
import { Box } from "@mui/material";
// import { Helmet } from "react-helmet";
import Service from "../../components/services-list";
import Expertise from "../../components/expertise-list";
import DevelopmentProcess from "../../components/development-process-list";
import BusinessModel from "../../components/business-model";
import LetDiscuss from "../../components/let-discuss";
import Portfolio from "../../components/portfolio-list";
import Blog from "../../components/blog";

function Home() {
  return (
    <Box>
                {/* <Helmet>
                    <title>F9 Technologies</title>
                </Helmet> */}
      <Hero />
      <Welcome />
      <Service />
      <Expertise />
      <DevelopmentProcess />
      <BusinessModel />
      <LetDiscuss />
      <Portfolio />
      <Blog/>
   
    </Box>
  );
}
export default Home;
