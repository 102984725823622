import React, { Component } from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import HeroImage from "../hero/hero-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Images from "../../components/theme-constants/image";
import "./hero.css";


export default class Hero extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <Box className="hero-section" sx={{ py:13 }}>
        <Container maxWidth="xl" className="container">
          <Slider {...settings}>
            <Box className="item">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid className="content" item xs={6}>
                  <Typography
                    variant="h3"
                    component="h1"
                    gutterBottom
                    alignItems="flex-end"
                  >
                    WE SHAPE YOUR
                  </Typography>
                  <img src={Images.IdeaImage.default} alt="F9tech" />
                  <Typography variant="body1" gutterBottom>
                  Ideas don't make you a millionaire, the execution does.
Every idea backed by an expert team can be made successful. 
Whether you have one great idea or 10, we would love to help.
                  </Typography>
                </Grid>
                <Grid className="pic" item xs={6}>
                  <HeroImage />
                </Grid>
              </Grid>
            </Box>
          </Slider>
        </Container>
      </Box>
    );
  }
}
