import React, { Component } from "react";
import {
  Container,
  Grid,
  Button,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";

class Contact extends Component {
  render() {
    return (
      <Box className="contact-page-section" sx={{ py: 13 }}>
 <Helmet>
                    <title>Contact Us | F9 Technologies</title>
                    <meta name="keywords" content="reach out to f9 tech in vizag, get IT services from F9, F9contact in vizag, f9 info technologies near Vizag"/>
                    <meta name="description" content="Discover F9 Info Technologies, located near Vizag, offering comprehensive IT solutions to drive your business growth and success"/>
                </Helmet>

        <Container maxWidth="xl" className="container">
          <Typography
            variant="h1"
            component={"h1"}
            className="page-title"
            align="center"
            sx={{ mb: 3 }}
          >
            Contact Us
          </Typography>
          <form>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid className="content" item xs={12} md={6} sx={{ mb: 3 }}>
                <TextField 
                  id="outlined-password-input"
                  label="Name"
                  type="text"
                  autoComplete="name"
                  fullWidth // Set fullWidth to make the TextField take up 100% width
                />
              </Grid>
              <Grid className="content" item xs={12} md={6} sx={{ mb: 3 }}>
                <TextField
                  id="outlined-password-input"
                  label="Email"
                  type="email"
                  autoComplete="email"
                  fullWidth // Set fullWidth to make the TextField take up 100% width
                />
              </Grid>
              <Grid className="content" item xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="outlined-password-input"
                  label="Subject"
                  type="text"
                  autoComplete="subject"
                  fullWidth // Set fullWidth to make the TextField take up 100% width
                />
              </Grid>
              <Grid className="content" item xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth // Set fullWidth to make the TextField take up 100% width
                />
              </Grid>
            </Grid>
            <Box align="center">
              <Button variant="contained">Contained</Button>
            </Box>
          </form>
        </Container>
      </Box>
    );
  }
}

export default Contact;
