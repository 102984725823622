import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Container, Box, Typography, Button } from "@mui/material";

class LetDiscuss extends Component {
  render() {
  
    return (
      <Box className="letDiscuss" sx={{ paddingBottom: '100px' }}>
        <Container maxWidth="xl" className="container">
          <Card className="card" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <CardContent className="card-content">
              <Typography component="div" variant="h5" className="heading">
                Let’s discuss your next Project
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div" className="paragraph">
                We're eager to hear your plans and help you get started.
              </Typography>
            </CardContent>
            <Button variant="contained" size="medium">Talk to Expert</Button>
          </Card>
        </Container>
      </Box>
    );
  }
}

export default LetDiscuss;