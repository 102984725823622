import { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import { Box } from "@mui/material";


function HeroImage() {

   const container =useRef(null)

   useEffect( () => { 
     Lottie.loadAnimation({
         container: container.current,
         renderer: 'svg',
         loop: true,
         autoplay: true,
         animationData: require('../hero/all.json')
     })
   }, [])

  return (
    <Box>
      <Box className='container' style={{ height:'400px',}} ref={container}></Box>
    </Box>
  )
}

export default HeroImage
