import React from "react";
import Sectiontitle from "../../components/section-title";
import { Container, Grid, Box, Typography, Link } from "@mui/material";
import './expertise.css' 
import Expertisecardlist from "../../lists/list-expertise"



function Expertise() {
  return (
    <Box className="home-expertise" sx={{ py: 13 }}>
      <Container maxWidth="xl" className="container">
        <Sectiontitle
          data={{
            mainTitle: "Expertise",
            subTitle:
              "Need any help with these technologies? Give us a buzz",
          }}
        />
        <Grid  className="expertise-list"
          container
          spacing={5}
          justifyContent="center"
          alignItems="center"
        >
          {Expertisecardlist.map((item, index) => (
            <Grid item key={index}  className="block">
                 <Link href={item.link} target="_blank">
              <img  src={item.imageUrl} alt={item.title} />
              <Typography component="p" variant="h5">{item.title}</Typography>
             </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
export default Expertise;
