import React, { Component } from "react";
import { Grid, Container, Box, Typography, useMediaQuery } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Images from "../../components/theme-constants/image";
import logo from "../../logo.svg";
import "./footer.css";


function Footer() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));


  return (
      <Box>
        <Box className="footer-top">
          <Container maxWidth="xl" className="container">
            <Grid
              container
              spacing={5}
              justifyContent="space-between"
              alignItems="end"
            >
              <Grid item xs={3} className="display-none">
                <Box className="footer-logo">
                  <Link href="/">
                    <img
                      src={logo}
                      className="App-logo"
                      alt="F9 Info Technologies"
                    />
                  </Link>
                </Box>
              </Grid>
              <Grid item className="footer-socail">
                <List>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.Linkedin.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.Twitter.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.Facebook.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.Instragram.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.GitHub.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={2} className="display-none">
                &nbsp;
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className="bg-secondary footer-middle" sx={{ py: 13 }}>
          <Container maxWidth="xl" className="container">
            {" "}
            <Container maxWidth="xl" className="container">
              <Grid
                container
                spacing={5}
                justifyContent="center"
                alignItems="start"
              >
                <Grid item xs={isSmallScreen ? 12 : 3} className="block">
                  <Box className="footer-nav">
                    <Typography className="footer-title">SERVICES</Typography>
                    <List>
                      <ListItem>
                        <Link href="#">MOBILE APP DEVELOPMENT</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">WEB APP DEVELOPMENT</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">DIGITAL MARKETING</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">GRAPHIC DESIGNING</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">UI/UX DESIGN</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">BUSINESS SOLUTIONS</Link>
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 3}  className="block">
                  <Box className="footer-nav">
                    <Typography className="footer-title">SOLUTIONS</Typography>
                    <List>
                      <ListItem>
                        <Link href="#">F9 CRM</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">ASSOCIATION MANAGEMENT</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">FLUTTER CMS</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">PR CMS</Link>
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 3}  className="block">
                  <Box className="footer-nav">
                    <Typography className="footer-title">
                      QUICK LINKS
                    </Typography>
                    <List>
                      <ListItem>
                        <Link href="#">OPEN SOURCE</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">PORTFOLIO</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">TEAM</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">CULTURE</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">OUR EXPERTISE</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">CAREERS</Link>
                      </ListItem>
                      <ListItem>
                        <Link href="#">FAQs</Link>
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 3}  className="block">
                  <Box className="footer-nav">
                    <Typography className="footer-phone">
                      <Link href="#">+91 891 969 3567</Link>
                    </Typography>
                    <Typography className="footer-email">
                      <Link href="#">support@f9tech.com</Link>
                    </Typography>
                    <Typography className="footer-address">
                      Vinayagar Heights, Sampath Vinayaka TempleRoad
                      <br />
                      Waltair Uplands, Opp. Kalyan Jewellers
                      <br />
                      Visakhapatnam – 530003, <br />
                      Andhra Pradesh (INDIA)
                      <br />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid className="footer-bottom"
                container
                spacing={5}
                justifyContent="space-between"
                alignItems="end"
              >
                <Grid item xs={isSmallScreen ? 12 : 4} >
                <List>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.KDL.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.BNI.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.CII.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.VS.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.GitHub.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                </List>
                </Grid>
                <Grid item className="footer-content">
                  <Typography>
                    ©2024 F9 Info Technologies Private Limited. All Rights
                    Reserved
                  </Typography>
                  <List>
                    <ListItem>
                      <Link href="#">TERMS & CONDITIONS</Link>
                    </ListItem>
                    <ListItem>
                      <Link href="#">PRIVACY POLICY </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="#">SITEMAP</Link>
                    </ListItem>
                    <ListItem>
                      <Link href="#">BLOGS</Link>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 3} >
                <List>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.DRMCA.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.Pro.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Box>
      </Box>
  );
}

export default Footer;
