import React  from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  List,
  ListItem, useMediaQuery
} from "@mui/material";
import Images from "../../../components/theme-constants/image";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CheckBoxIcon from "@mui/icons-material/CheckBox";


function Vision() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
   
    return (
      <Box className="vision-section">
        <Container maxWidth="xl" className="container">
          <Box className="card">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid className="content order2" item xs={isSmallScreen ? 12 : 7} >
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component="div" variant="h5">
                    We are about providing the powerful solutions which are
                    simple and effient which would help them with reaching their
                    goals and building a realtionship with the help of good
                    communication between the teams to provide long-term and
                    updated solutions
                  </Typography>
                </CardContent>
              </Grid>
              <Grid className="text-right order1" item>
                <figure>
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={Images.V1.default}
                    alt="f9 tech"
                  />
                </figure>
              </Grid>
            </Grid>
          </Box>
          <Box className="card">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid className="text-right order1" item>
                <figure>
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={Images.V2.default}
                    alt="f9 tech"
                  />
                </figure>
              </Grid>
              <Grid className="content order2" item xs={isSmallScreen ? 12 : 7} >
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component="div" variant="h5">
                    Providing futuristic applications with latest technologies
                    for both mobile and web based applications in agile method,
                    proper project management protocals and proper communication
                    with our clients who are based in different locations all
                    over the world
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Box>
          <Box className="card">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid className="content order2" item xs={isSmallScreen ? 12 : 4} >
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <List>
                    <ListItem><CheckBoxIcon /> Passion</ListItem>
                    <ListItem><CheckBoxIcon /> Employee Satisfaction</ListItem>
                    <ListItem><CheckBoxIcon /> Integrity</ListItem>
                    <ListItem><CheckBoxIcon /> Humble</ListItem>
                  </List>
                </CardContent>
              </Grid>
              <Grid className="text-center order1" item xs={isSmallScreen ? 12 : 4} >
                <figure>
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={Images.V3.default}
                    alt="f9 tech"
                  />
                </figure>
              </Grid>
              <Grid className="content order2" item xs={isSmallScreen ? 12 : 4} >
                <CardContent sx={{ flex: "1 0 auto" }}>
                <List>
                    <ListItem><CheckBoxIcon /> Customer Focused</ListItem>
                    <ListItem><CheckBoxIcon /> Discipline</ListItem>
                    <ListItem><CheckBoxIcon /> Collaboration</ListItem>
                    <ListItem><CheckBoxIcon /> Commitment</ListItem>
                  </List>
                </CardContent>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
   );
  }
  
  export default Vision;