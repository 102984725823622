import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Home from "./pages/home"
import About from "./pages/about-us";
import Services from "./pages/services";

import Solutions from "./pages/solutions";
import Contact from "./pages/contact-us";

import './components/theme-constants/color/color.css';
import './components/theme-constants/global/global.css';

import {Route, Routes} from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";

import './components/theme-constants/global/responsive.css';


const theme = createTheme({
  palette: {
    primary: {
      light: "#8fb6f0",
      main: "#2B74E2",
      dark: "#124187",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ed9491",
      main: "#DE3730",
      dark: "#841915",
      contrastText: "#000",
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="theme">   

      <Header/>
  
      <Routes>
          <Route exact path="/" Component={Home} />
          <Route exact path="/services" Component={Services} />
          <Route exact path="/solutions" Component={Solutions} />
          <Route exact path="/about" Component={About} />
          <Route exact path="/contact" Component={Contact} />
      </Routes>
<Footer/>

      </div>
    </ThemeProvider>
  );
}
export default App;
