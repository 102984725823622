import React from "react";
import { Button, ListItem, List, Link } from "@mui/material";
import "./nav.css";
function Nav() {
  return (
    <nav className="nav" align="right">
      <List>
        <ListItem>
          <Link href="/" variant="h6" underline="none" fontWeight={400}>
            Home
          </Link>
        </ListItem>
        <ListItem>
          <Link href="/services" variant="h6" underline="none" fontWeight={400}>
            Services
          </Link>
        </ListItem>
        <ListItem>
          <Link href="/solutions" variant="h6" underline="none" fontWeight={400}>
            Solutions
          </Link>
        </ListItem>
        <ListItem>
          <Link href="/about" variant="h6" underline="none" fontWeight={400}>
            About us
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://medium.com/@f9tech" target="_blank" variant="h6" underline="none" fontWeight={400}>
            Blog
          </Link>
        </ListItem>
        <ListItem>
          <Button
            className="top-button"
            variant="contained"
            href="/contact"
            fontWeight={400}
          >
            Contact us
          </Button>
        </ListItem>
      </List>
    </nav>
  );
}
export default Nav;
