import React, { Component } from "react";
import { Container, Grid, Typography, Box, useMediaQuery } from "@mui/material";
import Images from "../../../components/theme-constants/image";
import CardMedia from "@mui/material/CardMedia";


function SolutionsTop() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));




   
    return (
      <Box className="solution-top-section" sx={{ pt:13 }}>
        <Container maxWidth="xl" className="container">
        <Box className="item">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid className="content order2" item xs={isSmallScreen ? 12 : 6}>
                  <Typography
                    variant="h3"
                    component="h2"
                    gutterBottom
                    alignItems="flex-end"
                  >
              <Typography variant="span"> We design and develop </Typography>
                user centric SaaS products, web and mobile applications since 16 years.

                  </Typography>
                  
                  <Typography variant="body1" gutterBottom>
                  We work hand in hand with our clinetele to create innovative solutions with powerful and efficient softwares.
                  </Typography>
                </Grid>
                <Grid className="pic order1" item xs={isSmallScreen ? 12 : 5}>
                 
                  <figure><CardMedia
                  component="img"
                 
                  image={Images.SolutionsImage}
                  alt="F9tech"
                /></figure>
                 
                </Grid>
              </Grid>
            </Box>
        </Container>
      </Box>
    );
  }

export default SolutionsTop;