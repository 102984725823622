const Expertisecardlist = [
  {
    title: "Flutter",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/1.svg").default,
  },
  {
    title: "React",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/2.svg").default,
  },
  {
    title: "Node.js",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/3.svg").default,
  },
  {
    title: "Type Script",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/4.svg").default,
  },
  {
    title: "Laravel",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/5.svg").default,
  },
  {
    title: "PHP",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/6.svg").default,
  },
  {
    title: "Material 3",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/7.svg").default,
  },
  {
    title: "Keycloak",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/8.svg").default,
  },
  {
    title: "Cloud",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/9.svg").default,
  },
  {
    title: "Wordpress",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/10.svg").default,
  },
  {
    title: "Drupal",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/11.svg").default,
  },
  {
    title: "Firebase",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/12.svg").default,
  },
  {
    title: "CSS3",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/13.svg").default,
  },
  {
    title: "HTML5",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/14.svg").default,
  },
  {
    title: "Dart",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/15.svg").default,
  },
  {
    title: "Github",
    link: "https://f9tech.com/",
    imageUrl: require("../images/expertise/16.svg").default,
  },
  
  // Add more items as needed
];
export default Expertisecardlist;
