import React, { useState, useEffect } from "react";
import Sectiontitle from "../../components/section-title";
import { Container, Grid, Box, Typography, useMediaQuery } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CardMedia from "@mui/material/CardMedia";
import "./index.css";
import BusinessModelList from '../../lists/list-business'

function BusinessModel() {
  const [activeIndex, setActiveIndex] = useState(0); // Initialize with index of first item
  const [intervalId, setIntervalId] = useState(null);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const handleListItemClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const id = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === BusinessModelList.length - 1 ? 0 : prevIndex + 1
      );
    }, 1 * 30 * 100); // 3 minutes in milliseconds
    setIntervalId(id);

    return () => clearInterval(id);
  }, []);

  const handleMouseEnter = () => {
    clearInterval(intervalId);
  };

  const handleMouseLeave = () => {
    const id = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === BusinessModelList.length - 1 ? 0 : prevIndex + 1
      );
    }, 3 * 60 * 1000); // 3 minutes in milliseconds
    setIntervalId(id);
  };

  return (
    <Box className="business-model" sx={{ py: 13 }}>
      <Container maxWidth="xl" className="container">
        <Sectiontitle
          data={{
            mainTitle: "Business Model",
            subTitle: "Choose the one that best fits for your next project",
          }}
        />
        <Grid container spacing={5} justifyContent="center" alignItems="left">
          <Grid item xs={isSmallScreen ? 12 : 6} className="left-block">
            <List>
              {BusinessModelList.map((item, index) => (
                <ListItem
                  key={index}
                  className={activeIndex === index ? "active" : ""}
                  onClick={() => handleListItemClick(index)}
            
                >
                  <Typography variant="h4">{item.title}</Typography>
                  <Typography>{item.body}</Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 6} className="right-block">
            <List>
              {BusinessModelList.map((item, index) => (
                <ListItem
                  key={index}
                  className={activeIndex === index ? "active" : ""}
                  onClick={() => handleListItemClick(index)}
             
                >
                  <figure>
                    <CardMedia
                      component="img"
                      image={item.imageUrl}
                      alt={item.title}
                    />
                  </figure>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default BusinessModel;
