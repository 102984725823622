import React from "react";
import Sectiontitle from "../../components/section-title";
import { Container, Grid, Box, useMediaQuery } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Images from "../../components/theme-constants/image";
import ProcessCard from "../custom-cards/process-card";

import DevelopmentProcessList from "../../lists/list-development";

function DevelopmentProcess() {
    // Select the first 8 items
    const developmentProcess = DevelopmentProcessList.slice(0, 8);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return (
        <Box className="bg-grey development-process" sx={{ py: 13 }}>
            <Container maxWidth="xl" className="container">
                <Sectiontitle
                    data={{
                        mainTitle: "Our Development Process",
                        subTitle: "We believe in developing with agility",
                    }}
                />
                <Grid container spacing={5} justifyContent="center" alignItems="left">
                    <Grid item xs={isSmallScreen ? 12 : 5}  className="development-left">
                        <figure>
                            <CardMedia
                                component="img"
                                image={Images.DevelopmentImage.default}
                                alt="Development Process"
                            />
                        </figure>
                    </Grid>
                    <Grid item  xs={isSmallScreen ? 12 : 7} className="development-right">
                        <Grid container spacing={3} justifyContent="center" alignItems="left">
                            {developmentProcess.map((item, index) => (
                                <Grid item xs={isSmallScreen ? 12 : 3} key={index}>
                                    <ProcessCard
                                        title={item.title}
                                        body={item.body}
                                        imageUrl={item.imageUrl}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default DevelopmentProcess;
