import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { List, Grid, useMediaQuery } from "@mui/material";

// Function to truncate a string to a specified length
const truncate = (str, maxLength) =>
  str.length > maxLength ? str.substring(0, maxLength - 3) + "..." : str;

export default function ServiceCardList({ title, body, imageUrl }) { // Corrected prop name to 'imageUrl'
  const truncatedTitle = truncate(title, 24); // Truncate the title
  const truncatedBody = truncate(body, 120); // Truncate the body
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <Card sx={{ display: "flex" }} className="services-list-card">
       <Grid container spacing={5} justifyContent="space-between" alignItems="start">
          <Grid item xs={isSmallScreen ? 12 : 8}  >

          <Typography component="div" variant="h3">
            {truncatedTitle}
          </Typography>
          <List>
            {truncatedBody}
          </List>
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 4}  >

          <CardMedia
            component="img"
            sx={{ width: 151 }}
            image={imageUrl} // Changed to 'imageUrl' prop
            alt="F9 Tech"
          />

          </Grid>
</Grid>
    </Card>
  );
}
